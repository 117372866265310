import React from 'react';
import digital from '../images/digital-services.png';
import goal from '../images/goal.png';
function Whoweare() {
  return (
    <section className="think-big p-relative sec-padd text-white">
        <div className="container">
            <div className="background-text">WHO WE ARE</div>
            <div className="content text-center">
            <h2 className="section-title mb-3">WHO WE ARE</h2>   
            <h6 className="text-center">We are RUNMYDIGITAL in MUMBAI and as innovators in creative digital marketing & website design, we build AWESOME digital masterpieces. Take a sneak peek at our team, offices, culture, philosophy and beliefs.</h6>
            </div>        
        </div>
        <div className="container">
            <div className="row justify-content-center align-items-center">
                <div className="col-md-4"><img className='img-fluid' src={digital} alt="Digital" /></div>
                <div className="col-md-8">
                    <h4>Magical People We Are. Really!</h4>
                    <p>After spending 10+ years in the Digital Marketing & Web Services arena we realized the need to offer the services ourselves to improve the quality of the services being offered to the clients. RunMyDigital is our corporate brand and under its umbrella we have many other initiatives.</p>
                </div>
            </div>
            <div className="row mt-sm-4 justify-content-center align-items-center">
            <div className="col-md-8">
                    <h4>Our Goals</h4>
                    <p>Since the inception we have worked with a diverse range of clients – from a premium brand from the house of Aditya Birla, Nuvo, a HK based publishing company (From the age of Times Magazine) to one of the top 10 colonial hotels which has been extensively covered by international media like The Sunday Times and The Independent. We also do consultation for digital marketing companies from India as well as the UK, USA and Australia.</p>
                </div>
                <div className="col-md-4"><img className='img-fluid' src={goal} alt="Goal" /></div>
            </div>
        </div>
    </section>
  )
}

export default Whoweare