import React from 'react';
import loupe from '../images/loupe.png';
import strategy from '../images/business-plan.png';
import websolution from '../images/creative.png';
import mobile from '../images/mobile.png';
import cms from '../images/cms.png';
import marketing from '../images/bullhorn.png';
function Expert() {
  return (
    <section className="expertise-section p-relative sec-padd" id='expertise'>
    <div className="container">
            <div className="background-text">Expertise</div>
            <div className="content text-center mb-3"><h2 className="section-title">EXPERTISE</h2></div>
            <div className="row">
                <div className="col-md-4 mb-4">
                    <div className="expert-wrap text-center border p-3 h-100 text-blue bg-white">
                        <div className="icon-wrap"><img className='icons' src={loupe} alt="Search" /></div>
                        <h5 className='subtitle my-3'>DISCOVERY</h5>
                        <p>A thorough discovery is at the core of every successful digital solution we craft. Understanding who you are building for and why you are building it is more important than the colors or technology you use. If you don't know these things first, the rest is pointless.</p>
                    </div>
                </div>
                <div className="col-md-4 mb-4">
                    <div className="expert-wrap text-center border p-3 h-100 text-blue bg-white">
                        <div className="icon-wrap"><img className='icons' src={strategy} alt="Strategy" /></div>
                        <h5 className='subtitle my-3'>STRATEGY & PLANNING</h5>
                        <p>Strategy & planning is everything. The needs of digital consumers are not the same today as they were yesterday. We have the experience and knowledge needed to create a smart strategy for your business to solve your digital problems.</p>
                    </div>
                </div>
                <div className="col-md-4 mb-4">
                    <div className="expert-wrap text-center border p-3 h-100 text-blue bg-white">
                        <div className="icon-wrap"><img className='icons' src={websolution} alt="websolution" /></div>
                        <h5 className='subtitle my-3'>WEBSITE SOLUTIONS</h5>
                        <p>Web design and development is our roots. Designing and developing for numerous screen sizes and devices takes precision, which is why our hand-crafted, innovative online solutions are second to none.</p>
                    </div>
                </div>
                <div className="col-md-4 mb-4 mb-sm-0">
                    <div className="expert-wrap text-center border p-3 h-100 text-blue bg-white">
                        <div className="icon-wrap"><img className='icons' src={mobile} alt="mobile" /></div>
                        <h5 className='subtitle my-3'>MOBILE & TABLET SOLUTIONS</h5>
                        <p>We are experts at delivering an engaging experience on mobile devices of all shapes and sizes. We know which best practices to follow and where we can push the envelope to deliver delight.</p>
                    </div>
                </div>
                <div className="col-md-4 mb-4 mb-sm-0">
                    <div className="expert-wrap text-center border p-3 h-100 text-blue bg-white">
                        <div className="icon-wrap"><img className='icons' src={cms} alt="cms" /></div>
                        <h5 className='subtitle my-3'>ENTERPRISE CMS SOLUTIONS</h5>
                        <p>If you are looking for the power and efficiencies found with enterprise content management solutions, we can help. Our enterprise solutions enable you to leverage enterprise content management platforms for your business.</p>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="expert-wrap text-center border p-3 h-100 text-blue bg-white">
                        <div className="icon-wrap"><img className='icons' src={marketing} alt="marketing" /></div>
                        <h5 className='subtitle my-3'>ONLINE MARKETING</h5>
                        <p>You've spent a lot of time and money getting your business to where it is today. Let us help you take your business to the next level with our digital marketing solutions. We'll make sure your company gets the exposure it needs to thrive.</p>
                    </div>
                </div>
            </div>  
        </div>
    </section>
  )
}

export default Expert