import React from 'react'
import clients from '../images/clients.png';
function Client() {
  return (
    <section className="client-section p-relative sec-padd" id='client'>
        <div className="container">
                <div className="background-text">Client</div>
                <div className="content text-center">
                    <h2 className="section-title">OUR CLIENTS</h2>
                    <h6 className="title-border my-3">We are proud to have some of the most well-known and respected corporate clients – but we love to help start-ups and new brands too. We aren’t just talk. We are in the game, in a big way, and are ready to partner with you.</h6> 
                    <div className="image-wrap"><img className='img-fluid' src={clients} alt="moclup" /></div>    
                </div>        
            </div>
    </section>
  )
}

export default Client