import React from 'react'

function Footer() {
  return (
    <footer style={{ backgroundColor: '#6684a3' }} className="text-white py-2">
      <div className="container mx-auto d-flex flex-column flex-sm-row justify-content-between align-items-center px-2 py-2">
        <p className="mb-2 mb-sm-0">&copy; Copyright 2024 Run My Digital</p>
        <p className="mb-2 mb-sm-0"> Designed & Developed By <a href="https://vartservices.com" target='_blank' style={{ color: 'white' }}>V-Art Services Pvt Ltd</a></p>
      </div>
    </footer>
  )
}

export default Footer