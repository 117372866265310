import React from 'react'
import mockup from '../images/website-mockup.png';
function Work() {
  return (
    <section className="work-section p-relative sec-padd" id='work'>
            <div className="container">
                    <div className="background-text">Work</div>
                <div className="content text-center">
                    <h2 className="section-title">RECENT WORK</h2>
                    <h5 className="title-border my-3">We have a deep understanding of user habits and behaviors. We are committed to using fact-based knowledge and our unique brand of innovation to help you dominate the competition. Every project is customized for optimum visibility and audience response.</h5> 
                    <div className="image-wrap"><img className='img-fluid' src={mockup} alt="moclup" /></div>    
                </div>        
            </div>
        </section>
  )
}

export default Work